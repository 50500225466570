import { Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import "../../assets/scss/Nav/Nav.scss";
import {
  HCP,
  hcpRestricted,
  homePage,
  patient,
  patientRestricted,
} from "../../constants/constants";
import { NavMenu } from "../../interfaces/NavMenu";
import { PageContents, PageType } from "../../interfaces/Pages";
import accountIcon from "../../resources/images/nav/profile.png";
import logoPng from "../../resources/logo/png/kabicare.png";
import logo from "../../resources/logo/webp/kabicare.webp";
import { AuthLink } from "../../routing/AuthLink";
import { LOGOUT } from "../../store/AuthActions";
import { authState, contentState } from "../../store/Reducer";
import { useQuery } from "../../util/util";
import { InverseButton } from "../InverseButton";
import HamburgerMenu from "./HamburgerMenu";
import MobileMenu from "./MobileNav";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const location = useLocation();
  let mobile = useQuery().get("mobile") === "true";
  const contents: PageContents = useSelector(contentState);
  const currentPage = contents[location.pathname.substr(1)];
  const navMenu =
    currentPage?.menu && Object.keys(currentPage?.menu).length !== 0
      ? currentPage?.menu
      : contents[PageType.homeOld]?.menu;
  const auth = useSelector(authState);
  const dispatch = useDispatch();
  const history = useHistory();
  const userRoles = auth.roles;
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileMenuClass, setMobileMenuClass] = useState("");
  const [menuItemWidths, collectMenuItemWidths] = useState<any[]>([]);

  const isHome = location.pathname === "/" + homePage;
  const ref = useRef<HTMLDivElement | null>(null);

  const menu = { ...navMenu };

  const { t } = useTranslation();

  useEffect(() => {
    if (ref.current) {
      const collection = ref.current.children;
      const arrCollection = [].slice.call(collection);
      const widths = arrCollection
        .filter((e: HTMLDivElement) => e.classList.contains("level-1"))
        .map((element: HTMLDivElement) => element.offsetWidth);
      collectMenuItemWidths(widths);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (mobile) return <></>;

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
    setMobileMenuClass("");
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
    setMobileMenuClass("menu-open");
  };

  const handleMobileMenu = () => {
    if (isMobileMenuOpen) {
      handleMobileMenuClose();
    } else {
      handleMobileMenuOpen();
    }
  };

  const handleLogin = () => {
    handleAuthAction("login");
  };

  const handleRegister = () => {
    handleAuthAction("register");
  };

  const handleAuthAction = (path) => {
    handleMobileMenuClose();
    history.push(`/${path}`);
  };

  const handleLogout = () => {
    handleMobileMenuClose();
    dispatch({ type: LOGOUT });
    history.push("/");
  };

  const handleLevelToggle = (event) => {
    const eventTarget = event.target;
    const targetParent = eventTarget.parentElement;
    const parentClasses = targetParent.classList;

    if (parentClasses.contains("open")) {
      parentClasses.add("closing");
      parentClasses.remove("open");

      const timer = setTimeout(() => {
        parentClasses.remove("closing");
      }, 500);
      return () => clearTimeout(timer);
    } else if (parentClasses.contains("closing")) {
      return;
    } else {
      parentClasses.add("open");
    }
  };

  const menuBridgeWidth = 70;
  const handleDynamicStyling = (width = 140) => {
    //if (window.innerWidth >= 1366) {
    if (window.innerWidth >= 1280) {
      return {};
    }

    return { left: width / 2 - menuBridgeWidth };
  };

  const checkRoles = (roles) => {
    if (roles?.content || roles[0] === "") {
      return true;
    } else if (roles.includes(patient) && userRoles.includes(patient)) {
      return true;
    } else if (roles.includes(HCP) && userRoles.includes(HCP)) {
      return true;
    } else if (
      roles.includes(hcpRestricted) ||
      roles.includes(patientRestricted)
    ) {
      return true;
    }

    return false;
  };

  const hasVisibleChildren = (children) => {
    return (
      Object.values(children).filter((c: any) => checkRoles(c.roles)).length !==
      0
    );
  };

  const renderMenu = (menu: NavMenu, renderLevel) => {
    return (
      menu &&
      Object.values(menu).map((menuItem, index) => {
        if ((menuItem?.roles as any)?.content) {
          menuItem.roles = [""];
        }

        if (!checkRoles(menuItem.roles)) {
          return <></>;
        }

        const classFromLabel = menuItem.label
          .toLowerCase()
          .replaceAll(" ", "-")
          .concat("-menu-item");

        return (
          <div
            className={`${renderLevel === 1 ? "level-" + renderLevel : ""} ${
              menuItem.child && renderLevel !== 1 ? "label" : ""
            } ${
              menuItem.child && hasVisibleChildren(menuItem.child)
                ? ["has-children", classFromLabel].join(" ")
                : classFromLabel
            }`}
            key={index}
          >
            <AuthLink
              to={decodeURIComponent(decodeURIComponent(menuItem.url))}
              roles={menuItem.roles}
              onClose={() => {}}
              className={
                location.pathname.includes(
                  menuItem.url.replaceAll("%252f", "/")
                )
                  ? "active"
                  : ""
              }
            >
              {menuItem.label}
            </AuthLink>
            {menuItem.child && hasVisibleChildren(menuItem.child) && (
              <>
                {renderLevel === 1 ? (
                  <>
                    <div className="children-indicator">
                      <span></span>
                      <span></span>
                    </div>
                    <div
                      className={"bridge"}
                      style={
                        renderLevel !== 1
                          ? handleDynamicStyling(menuItemWidths[index])
                          : {}
                      }
                    ></div>
                  </>
                ) : (
                  <div
                    className="children-indicator-container"
                    onClick={handleLevelToggle}
                  >
                    <div className="children-indicator right-side">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )}

                <div
                  className={`level-${renderLevel + 1}`}
                  style={
                    renderLevel !== 1
                      ? handleDynamicStyling(menuItemWidths[index])
                      : {}
                  }
                >
                  {renderMenu(menuItem.child, renderLevel + 1)}
                </div>
              </>
            )}
          </div>
        );
      })
    );
  };

  let headerClasses = "header";
  headerClasses += isHome ? " home" : "";
  headerClasses += mobileMenuClass ? ` ${mobileMenuClass}` : "";

  return (
    <div className={headerClasses}>
      <div className="side-container">
        <div className="left-side">
          <RouterLink to="/">
            {
              <picture>
                <source
                  media="(min-width:1280px)"
                  srcSet={logo}
                  type="image/webp"
                />
                <source
                  media="(min-width:1280px)"
                  srcSet={logoPng}
                  type="image/jpeg"
                />
                <source srcSet={logo} type="image/webp" />
                <img src={logoPng} alt="logo" width="178px" height="45px" />
              </picture>
            }
          </RouterLink>
        </div>
        <div className="right-side" ref={ref}>
          <div className="menu-element-levels">{renderMenu(menu, 1)}</div>

          <div className="auth-button-container">
            {auth.authenticated ? (
              <>
                <div className="account-button-container">
                  <RouterLink to="/profile">
                    <Button
                      className="account-button auth-button"
                      aria-label="account-button"
                    >
                      <img
                        src={accountIcon}
                        alt="account"
                        height="27"
                        width="21"
                      />
                    </Button>
                  </RouterLink>
                </div>
                <InverseButton
                  onClick={handleLogout}
                  className="logout-button auth-button"
                  aria-label="logout"
                >
                  {t("nav.button.logout.label")}
                </InverseButton>
              </>
            ) : (
              <>
                <InverseButton
                  onClick={handleLogin}
                  className={"auth-button login-button"}
                  aria-label="login"
                >
                  {t("nav.button.login.label")}
                </InverseButton>
                <Button
                  onClick={handleRegister}
                  className={"auth-button register-button"}
                  aria-label="login"
                >
                  {t("nav.button.register.label")}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <MobileMenu
          authenticated={auth.authenticated}
          menu={menu}
          onClose={handleMobileMenuClose}
          onLogin={handleLogin}
          onLogout={handleLogout}
          onRegister={handleRegister}
          isOpen={isMobileMenuOpen}
          logoutLabel={t("nav.button.logout.label")}
        />
      )}
      <HamburgerMenu onOpen={handleMobileMenu} />
    </div>
  );
}
