import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY || "";

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoder = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
  terms: { value: string }[];
  place_id?: string;
}

export default function GoogleAutocomplete(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState<PlaceType | null>(props.value);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () => (
      request: { input: string },
      callback: (results?: PlaceType[]) => void
    ) => {
      (autocompleteService.current as any).getPlacePredictions(
        request,
        callback
      );
    },
    []
  );

  const fetchPostCode = (placeId, address) => {
    (geocoder.current as any).geocode(
      { placeId: placeId },
      (results, status) => {
        if (results && results[0]) {
          const last =
            results[0]?.address_components[
              results[0]?.address_components.length - 1
            ];

          if (last?.types[0] === "postal_code") {
            props.onPostCodeChange(last.long_name);
          }
        }
      }
    );
  };

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }

    if (!geocoder.current && (window as any).google) {
      geocoder.current = new (window as any).google.maps.Geocoder();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOption;
        const newOptionValues = inputValue
          .split(",")
          .map((s) => s.trim())
          .map((s) => {
            return { value: s };
          });

        if (newOptionValues.length >= 3) {
          newOption = {
            description: inputValue,
            structured_formatting: {
              main_text: newOptionValues[0].value,
              secondary_text: newOptionValues[1].value.concat(
                `, ${newOptionValues[2].value}`
              ),
              main_text_matched_substrings: [
                {
                  offset: 0,
                  length: 0,
                },
              ],
            },
            terms: newOptionValues,
          } as PlaceType;
        }

        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results && newOption)
          newOptions = [newOption, ...newOptions, ...results];
        else if (results) newOptions = [...newOptions, ...results];

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="address-line1"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      className={"select-container select-grid"}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event: React.ChangeEvent<{}>, newValue: any) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        if (newValue) {
          fetchPostCode(newValue.place_id, newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        props.onAddressChange(newInputValue);
      }}
      popupIcon={<ExpandMoreIcon className="Select__Icon" />}
      renderInput={(params: any) => (
        <TextField
          {...params}
          {...props}
          className="select-container select-grid"
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
